.cursor {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    contain: layout style size;
    pointer-events: none;
    &.media-blend {
      z-index: 100;
      mix-blend-mode: exclusion;
      // .cursor-media {
      //   filter: invert(1);
      // }
    }
    &:before {
      content: "";
      position: absolute;
      top: -24px;
      left: -24px;
      display: block;
      width: 48px;
      height: 48px;
      transform: scale(0.2);
      background: black;
      border-radius: 50%;
    }
    .cursor-media {
      display: block;
      height: 150px;
      width: 150px;
      overflow: hidden;
      border-radius: 100%;
      transform: scale(0);
      margin: -90px 0 0 -90px;
      position: relative;
      z-index: 99;
      video {
        height: 180px;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
      }
    }
  }
  