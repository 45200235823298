form {
  width: 100%;
}

form#account .controls {
  margin-top: 0;
}

form section {
  border-bottom: 1px solid #003b6933;
  padding: 30px 0;
}

form section:first-child {
  padding: 15px 0 30px;
}

form input.btn {
  width: initial;
}

form .delete {
  background: #d30023;
}

form .form-title {
  margin-bottom: 10px;
}

form .form-item, form label, form input, form textarea {
  color: #828282;
  width: 100%;
  font-weight: 300;
}

form .form-group {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
}

form span.form-required {
  color: #d30023;
}

form label {
  text-align: left;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

form input[type="date"], form input[type="search"], form input[type="url"], form input[type="text"], form input[type="password"], form input[type="email"], form input[type="tel"], form input[type="number"], form textarea.form-control {
  color: #828282;
  background-color: #fcfdfe;
  border: 1px solid #dee1e0;
  border-radius: 4px;
  align-items: center;
  padding: 0 10px;
  font-weight: 300;
  display: flex;
  position: relative;
}

form input[type="date"]:focus, form input[type="date"]:focus-visible, form input[type="search"]:focus, form input[type="search"]:focus-visible, form input[type="url"]:focus, form input[type="url"]:focus-visible, form input[type="text"]:focus, form input[type="text"]:focus-visible, form input[type="password"]:focus, form input[type="password"]:focus-visible, form input[type="email"]:focus, form input[type="email"]:focus-visible, form input[type="tel"]:focus, form input[type="tel"]:focus-visible, form input[type="number"]:focus, form input[type="number"]:focus-visible, form textarea.form-control:focus, form textarea.form-control:focus-visible {
  box-shadow: none;
  border: 1px solid #003b6966;
  outline: none;
}

form textarea.form-control {
  justify-content: flex-start;
  min-height: 120px;
  padding: 10px;
  display: flex;
}

form input[type="password"] {
  letter-spacing: 5px;
}

form input {
  height: 54px;
}

form #login-button, form #reset-password {
  color: #fff;
  background-color: #003b69;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  margin-bottom: 20px;
  transition: background-color .3s ease-in-out;
  display: flex;
}

form #login-button:hover, form #reset-password:hover {
  background-color: #0d6efd;
}

form #btnRegister {
  color: #fff;
  background-color: #003b69;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 54px;
  margin-bottom: 20px;
  transition: background-color .3s ease-in-out;
  display: flex;
}

form #btnRegister:hover {
  background-color: #0d6efd;
}

form .checkbox {
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  display: flex;
}

form .checkbox input[type="checkbox"] {
  width: initial;
  margin-right: 7px;
}

form .controls {
  justify-content: space-between;
  display: flex;
}

form .controls .form-group {
  width: initial;
}

form .forgot-password {
  text-align: right;
  font-size: 14px;
  font-weight: 500;
}

.control {
  cursor: pointer;
  margin-bottom: 5px;
  padding-left: 30px;
  font-size: 14px;
  line-height: 20px;
  display: block;
  position: relative;
}

.control input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.control input:checked ~ .control_indicator {
  background: #0d6efd;
  border: 1px solid #0d6efd;
}

.control input:checked:focus ~ .control_indicator, .control:hover input:not([disabled]):checked ~ .control_indicator {
  background: #0d6efd;
}

.control input:disabled ~ .control_indicator {
  opacity: .6;
  pointer-events: none;
  background: #e6e6e6;
  border: 1px solid #ccc;
}

.control input:checked ~ .control_indicator:after {
  display: block;
}

.control_indicator {
  background: #fff;
  border: 1px solid #003b6933;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 1px;
  left: 0;
}

.control_indicator:after {
  box-sizing: unset;
  content: "";
  display: none;
  position: absolute;
}

.control-checkbox .control_indicator:after {
  border: 2px solid #fff;
  border-width: 0 2px 2px 0;
  width: 3px;
  height: 8px;
  top: 3px;
  left: 7px;
  transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

.control-checkbox .control_indicator:before {
  content: "";
  opacity: .6;
  z-index: 99999;
  background: #2aa1c0;
  border-radius: 3rem;
  width: 4.5rem;
  height: 4.5rem;
  margin-top: -1.3rem;
  margin-left: -1.3rem;
  display: block;
  position: absolute;
  top: -5px;
  left: -7px;
  transform: scale(0);
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.control-checkbox input + .control_indicator:before {
  animation: .25s ease-out s-ripple;
}

.control-checkbox input:checked + .control_indicator:before {
  animation-name: s-ripple-dup;
}

input:-internal-autofill-selected {
  color: #828282 !important;
}

.form-container {
  background: #fff;
  border-radius: 10px;
  min-height: 421px;
  margin: 50px 0;
  padding: 30px 30px 20px;
  position: relative;
}

.form-container.is-complete .form-elements, .form-container.is-validating .form-elements, .form-container.is-duplicate .form-elements {
  display: none;
}

.form-container.is-complete .logo, .form-container.is-validating .logo, .form-container.is-duplicate .logo {
  width: calc(100% - 60px);
  position: absolute;
  bottom: 30px;
}

.form-container.is-complete .message__thankyou, .form-container.is-validating .message__validating, .form-container.is-duplicate .message__duplicate {
  display: flex;
}

.form-container .message__thankyou, .form-container .message__validating, .form-container .message__duplicate {
  text-align: center;
  flex-direction: column;
  width: calc(100% - 60px);
  height: 308px;
  padding: 0 30px;
  display: none;
  position: absolute;
  top: 30px;
}

.form-container .message__thankyou .form__title, .form-container .message__validating .form__title, .form-container .message__duplicate .form__title {
  text-align: center;
  margin: 10px 0;
}

.form-container .message__thankyou .form__title h4, .form-container .message__validating .form__title h4, .form-container .message__duplicate .form__title h4 {
  text-align: center;
}

.form-container .message__thankyou .message__image, .form-container .message__validating .message__image, .form-container .message__duplicate .message__image {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  display: flex;
}

.form-container .message__thankyou a, .form-container .message__validating a, .form-container .message__duplicate a {
  display: inline-block;
}

form .form-group {
  margin-bottom: 15px;
}

form .form-group input[type="text"], form .form-group input[type="password"], form .form-group input[type="email"], form .form-group input[type="tel"] {
  height: 44px;
  padding-right: 50px;
}

#btnRegister {
  height: 44px;
  margin: 20px 0 5px;
}

.existing-user {
  font-size: 14px;
  display: flex;
}

.existing-user a {
  margin-left: 5px;
  font-weight: 500;
}

.logo {
  justify-content: center;
  margin-top: 30px;
  display: flex;
}

.view-password {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 32px;
  right: 10px;
}

#lottie_thankyou, #lottie_validating, #lottie_duplicate {
  justify-content: center;
  align-items: center;
  display: flex;
}

#lottie_thankyou {
  max-width: 100px;
  height: auto;
}

#lottie_duplicate {
  max-width: 80px;
  height: auto;
}

.form-label {
  margin-bottom: 0;
}

.the-count {
  color: #828282;
  font-size: 14px;
}

.switch {
  width: 25px;
  height: 10px;
  display: inline-block;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  cursor: pointer;
  background-color: #ccc;
  transition: all .4s;
  position: absolute;
  inset: 0;
}

.switch .slider:before {
  content: "";
  background-color: #fff;
  border: 1px solid #dee1e0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all .4s;
  position: absolute;
  bottom: -5px;
  left: -11px;
  box-shadow: 0 3px 6px #003e611a;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.bg-light {
  background: #eef0f4;
}

.choices__list--dropdown .choices__item--selectable {
  padding-right: 1rem;
}

.choices__list--single {
  padding: 0;
}

.card {
  transform: translateY(-50%);
}

.choices[data-type*="select-one"]:after {
  right: 1.5rem;
}

.shadow {
  box-shadow: .3rem .3rem 1rem #b2c8f43b;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all .3s;
}

.form-group.dropdown .custom-select-wrapper {
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  position: relative;
}

.form-group.dropdown .custom-select-wrapper .custom-select {
  flex-direction: column;
  display: flex;
  position: relative;
}

.form-group.dropdown .custom-select-wrapper .custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger {
  color: #828282;
  cursor: pointer;
  background: #fcfdfe;
  border: 1px solid #dee1e0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  position: relative;
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger .arrow {
  width: 15px;
  height: 15px;
  position: relative;
  transform: scale(.4);
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger .arrow:before, .form-group.dropdown .custom-select-wrapper .custom-select__trigger .arrow:after {
  content: "";
  width: .15rem;
  height: 100%;
  transition: all .5s;
  position: absolute;
  bottom: 0;
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger .arrow:before {
  background-color: #394a6d;
  left: -5px;
  transform: rotate(45deg);
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger .arrow:after {
  background-color: #394a6d;
  left: 5px;
  transform: rotate(-45deg);
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger .open .arrow:before {
  left: -5px;
  transform: rotate(-45deg);
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger .open .arrow:after {
  left: 5px;
  transform: rotate(45deg);
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger span {
  color: #828282;
  margin-right: 10px;
  font-weight: 500;
}

.form-group.dropdown .custom-select-wrapper .custom-select__trigger span:before {
  content: "";
  color: #777;
  margin-right: 5px;
  font-weight: 500;
}

.form-group.dropdown .custom-select-wrapper .custom-select .custom-options {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  background: #fff;
  border: 1px solid #dee1e0;
  width: 100%;
  padding: 15px;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 43px;
  left: 0;
  box-shadow: 0 10px 30px #00000026;
}

.form-group.dropdown .custom-select-wrapper .custom-select .custom-options .custom-option {
  color: #777;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  transition: all .5s;
  display: block;
  position: relative;
}

.form-group.dropdown .custom-select-wrapper .custom-select .custom-options .custom-option:hover {
  cursor: pointer;
  background-color: #fff;
}

.form-group.dropdown .custom-select-wrapper .custom-select .custom-options .custom-option.selected {
  color: #7779;
}

.date-range {
  width: 200px;
  max-width: 200px;
}

#ToDate, #FromDate {
  border-radius: 4px;
}

.range {
  width: 50px;
  height: 22px;
  position: relative;
  top: 24px;
}

.range .line {
  background: #003b6933;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 22px;
}

.range .line:before, .range .line:after {
  content: "";
  z-index: 1;
  background: #0d6efd;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: -1px;
}

.range .line:before {
  left: -1px;
}

.range .line:after {
  right: -2px;
}

.form-group.calendar {
  display: flex;
}

.input-daterange {
  flex-direction: column;
  display: flex;
}

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 99;
}

.controls {
  margin-top: 50px;
}

.controls.single {
  justify-content: flex-end;
  display: flex;
}

.controls.full {
  justify-content: center;
  display: flex;
}

.controls.full .button, .controls.full .button button {
  width: 100%;
}

.ds-btn {
  color: #fff;
  cursor: pointer;
  background: #003b69;
  border: 1px solid #0000;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 15px;
  font-size: 14px;
  transition: all .3s ease-in-out;
  display: flex;
  position: relative;
}

.ds-btn:hover, .ds-btn:focus {
  text-decoration: none;
}

.ds-btn.next {
  padding: 0 15px 0 20px;
}

.ds-btn.next .icon {
  color: #fff;
  margin-left: 15px;
  font-size: 13px;
  transform: rotate(-90deg);
}

.ds-btn.text {
  color: #003b69;
  height: initial;
  background: none;
  border: none;
  display: inline-block;
}

.ds-btn.text:hover {
  color: #003b69;
  background: none;
  border: none;
}

.ds-btn.outline {
  color: #003b69;
  background: #fff;
  border: 1px solid #003b6933;
  padding: 0 25px;
}

.ds-btn.outline .icon {
  color: #0d6efd;
  margin-right: 5px;
  font-size: 20px;
  position: relative;
  top: 1px;
}

.ds-btn.outline.previous {
  padding: 0 25px 0 10px;
}

.ds-btn.outline.previous .icon {
  color: #003b69;
  margin-right: 12px;
  font-size: 13px;
  top: 0;
  transform: rotate(90deg);
}

.ds-btn.outline:focus .icon, .ds-btn.outline:hover .icon, .ds-btn.outline:active .icon {
  color: #fff;
}

.ds-btn:focus {
  color: #fff;
  background: #0d6efd;
  border: 1px solid #0000;
}

.ds-btn:hover {
  color: #fff;
  background: #0d6efd;
}

.clear-form {
  color: #003b69;
  cursor: pointer;
  font-size: 14px;
}

.clear-form:hover {
  text-decoration: underline;
}

.action {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.action__btn {
  color: #003b69;
  background: #fff;
  border: 1px solid #003b6933;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  height: 34px;
  margin: 0 5px;
  padding: 0 20px;
  font-size: 14px;
  transition: all .3s ease-in-out;
  display: flex;
}

.action__btn:hover {
  background: #e1e1e1;
}

.action #publish {
  color: #fff;
  background: #003b69;
}

.action #publish:hover {
  background: #0d6efd;
}

button.add_btn {
  color: #0d6efd;
  background: none;
  border: none;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 500;
}

button.add_btn .icon {
  position: relative;
  top: 2px;
}

.modal-header button {
  color: #0d6efd;
}

.btn-text {
  color: #0d6efd;
  background: none;
  border: none;
  justify-content: center;
  align-items: center;
  display: inline-block;
}

.back-btn {
  color: #0d6efd;
  background: none;
  border: none;
}

.back-btn .icon {
  margin-right: 4px;
  font-size: 14px;
  display: inline-block;
  transform: rotate(90deg);
}

.dropdown-menu {
  text-align: left;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  list-style: none;
  box-shadow: 0 0 15px #003e611a;
}

.dropdown-menu .dropdown-item {
  color: #828282;
  font-size: 14px;
  font-weight: 300;
}

.btn-group button:after {
  vertical-align: .255em;
  content: "";
  border: .25em solid #0000;
  border-top: .3em solid;
  border-bottom: 0;
  margin-left: 10px;
  display: inline-block;
}

.btn-group button.logout:after {
  content: "";
  display: none;
}

.btn-group ul {
  padding-left: 0;
}

html {
  scroll-behavior: smooth;
}

#header {
  height: 100px;
}

#footer {
  height: 60px;
}

.page {
  width: 100%;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  font-weight: 300;
  position: relative;
  overflow: hidden;
}

.page__header {
  border-bottom: 1px solid #003b6933;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 40px;
  display: flex;
}

@media (width <= 767px) {
  .page__header {
    height: 75px;
  }
}

@media (width <= 600px) {
  .page__header {
    padding: 0 15px;
  }
}

.page__header__left {
  color: #777;
  align-items: center;
  display: flex;
}

.page__header__left .dpird__logo {
  align-items: center;
  display: flex;
}

.page__header__left .dpird__logo img {
  width: 180px;
  max-width: 180px;
  height: auto;
  margin-right: 20px;
}

@media (width <= 400px) {
  .page__header__left .dpird__logo img {
    width: 90px;
    max-width: 90px;
  }
}

.page__header__left .logo {
  margin: 20px;
}

.page__header__left .logo img {
  width: 180px;
  max-width: 180px;
  height: auto;
}

@media (width <= 767px) {
  .page__header__left .logo img {
    width: 120px;
    max-width: 120px;
  }
}

@media (width <= 400px) {
  .page__header__left .logo img {
    width: 90px;
    max-width: 90px;
  }
}

.page__header__right .statement {
  color: #828282;
}

.page__header__right .statement img {
  max-width: 150px;
}

@media (width <= 991px) {
  .page__header__right .statement img {
    max-width: 125px;
  }
}

@media (width <= 767px) {
  .page__header__right .statement img {
    max-width: 100px;
  }
}

@media (width <= 400px) {
  .page__header__right .statement img {
    width: 80px;
    max-width: 80px;
  }
}

.page__introduction {
  padding: 75px 0;
}

@media (width <= 1400px) {
  .page__introduction {
    padding: 50px 0;
  }

  .page__introduction .container {
    max-width: 610px;
  }
}

@media (width <= 1400px) and (width <= 767px) {
  .page__introduction .container {
    width: 100%;
    padding: 0 30px;
  }
}

.page__footer {
  color: #828282;
  border-top: 1px solid #003b6933;
  justify-content: space-between;
  height: 30px;
  padding: 15px 40px;
  font-size: 14px;
  display: block;
}

.page__footer .row {
  text-align: right;
}

.page__lead {
  text-transform: uppercase;
  color: #121212;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 18px;
}

.page__headline__mobile {
  display: none;
}

@media (width <= 767px) {
  .page__headline__mobile {
    flex-direction: column;
    display: flex;
  }

  .page__headline__mobile .line {
    width: 100%;
    height: 40px;
    display: block;
    overflow: hidden;
  }

  .page__headline__mobile .line h2 {
    font-size: 34px;
    line-height: 40px;
    transform: translateY(40px);
  }
}

@media (width <= 500px) {
  .page__headline__mobile {
    flex-direction: column;
    display: flex;
  }

  .page__headline__mobile .line {
    width: 100%;
    height: 32px;
    display: block;
    overflow: hidden;
  }

  .page__headline__mobile .line h2 {
    font-size: 24px;
    line-height: 32px;
    transform: translateY(32px);
  }
}

.page__headline {
  flex-direction: column;
  display: flex;
}

@media (width <= 767px) {
  .page__headline {
    display: none;
  }
}

.page__headline .line {
  width: 100%;
  height: 40px;
  display: block;
  overflow: hidden;
}

@media (width <= 767px) {
  .page__headline .line {
    height: 24px;
  }
}

.page__headline .line h2 {
  color: #121212;
  font-size: 34px;
  line-height: 40px;
  transform: translateY(50px);
}

@media (width <= 767px) {
  .page__headline .line h2 {
    font-size: 17px;
    line-height: 24px;
    transform: translateY(24px);
  }
}

.page__list {
  flex-wrap: wrap;
  margin-top: 50px;
  display: flex;
}

@media (width <= 1400px) {
  .page__list {
    width: 560px;
    padding: 0 0 20px 30px;
  }
}

@media (width <= 600px) {
  .page__list {
    width: 100%;
    margin-top: 30px;
    padding: 0 0 20px 30px;
  }
}

.page__list__item {
  opacity: 0;
  flex: 0 0 50%;
}

@media (width <= 600px) {
  .page__list__item {
    flex: 0 0 100%;
  }
}

.page__list__item .list-item {
  text-transform: uppercase;
  width: 170px;
  padding-bottom: 30px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

@media (width <= 600px) {
  .page__list__item .list-item {
    width: 100%;
    padding-bottom: 20px;
  }
}

.page__list__item .list-item.beacon:after {
  content: "";
  z-index: -1;
  box-sizing: border-box;
  background: none;
  border: 1px solid #003b6933;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition-property: all;
  transition-duration: .36s;
  position: absolute;
  top: 9px;
  left: -25px;
  transform: translate(-50%, -50%);
}

.page__list__item .list-item.beacon:hover:after {
  opacity: 1;
  width: 70px;
  height: 70px;
}

.page__list__item .list-item:before {
  content: "";
  z-index: 2;
  box-sizing: border-box;
  background: none;
  border: 2px solid #0d6efd;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 4px;
  left: -30px;
}

.page .cta button {
  color: #121212;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  position: relative;
}

.page .cta button .icon {
  font-size: 24px;
  position: absolute;
  top: -1px;
  right: -35px;
}

.page .cta button:hover {
  color: #0d6efd;
}

.page .hero {
  width: 100%;
  height: 700px;
  position: relative;
  overflow: hidden;
}

@media (width <= 1400px) {
  .page .hero {
    height: 500px;
  }
}

.page .hero__image {
  background: url("extrata-hero.a891183d.jpg") center / cover;
  width: 100%;
  height: 700px;
}

@media (width <= 1400px) {
  .page .hero__image {
    height: 500px;
  }
}

.page .registration {
  justify-content: center;
  width: 100%;
  padding: 75px 0;
  display: flex;
  position: relative;
}

@media (width <= 767px) {
  .page .registration {
    padding: 50px 0;
  }
}

.page .registration__form {
  width: 800px;
  position: relative;
}

@media (width <= 1400px) {
  .page .registration__form {
    width: 610px;
    max-width: 610px;
  }
}

@media (width <= 767px) {
  .page .registration__form {
    width: 100%;
    padding: 0 30px;
  }
}

.page .registration__form form {
  opacity: 0;
}

.page .registration__process {
  z-index: 99;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 75px 15px;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.page .registration__process p {
  margin: 0;
}

@media (width <= 767px) {
  .page .registration__process p {
    font-size: 14px;
  }

  .page .registration__process h4 {
    font-size: 16px;
  }
}

.page .registration__process .message__validating, .page .registration__process .message__thankyou, .page .registration__process .message__error {
  flex-direction: column;
  display: none;
  position: relative;
}

.page .registration__process .message__validating .message__image, .page .registration__process .message__thankyou .message__image, .page .registration__process .message__error .message__image {
  justify-content: center;
  align-items: center;
  display: flex;
}

.page .registration__description {
  margin-bottom: 20px;
  font-size: 30px;
  line-height: 40px;
}

@media (width <= 1400px) {
  .page .registration__description {
    max-width: 600px;
  }
}

@media (width <= 767px) {
  .page .registration__description {
    width: 100%;
    font-size: 22px;
    line-height: 30px;
  }
}

.page .registration__required {
  color: #828282;
  margin: 0 0 20px;
  font-size: 14px;
  line-height: 18px;
}

@media (width <= 600px) {
  .page .registration .controls {
    margin-top: 10px;
  }
}

.registered {
  vertical-align: super;
  font-size: 14px;
}

.required {
  color: #d30023;
}

.link {
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.link:before, .link:after {
  pointer-events: none;
  background: currentColor;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 90%;
  left: 0;
}

.link:before {
  content: "";
  transform-origin: 100%;
  transition: transform .3s;
  transform: scale3d(0, 1, 1);
}

.link:hover:before {
  transform-origin: 0%;
  transform: scale3d(1, 1, 1);
}

.registration.is-validating {
  height: 720px;
}

.registration.is-validating .registration__process {
  display: flex;
}

.registration.is-validating .message__image {
  position: relative;
  top: 50px;
}

.registration.is-validating .message__validating {
  display: flex;
}

.registration.is-validating .registration__form {
  display: none;
}

.registration.is-complete {
  height: 720px;
}

.registration.is-complete .registration__process {
  display: flex;
}

.registration.is-complete .message__validating {
  display: none;
}

.registration.is-complete .message__thankyou {
  display: flex;
}

.registration.is-complete .registration__form {
  display: none;
}

.registration.is-error {
  height: 720px;
}

.registration.is-error .registration__process {
  display: flex;
}

.registration.is-error .message__validating, .registration.is-error .message__thankyou {
  display: none;
}

.registration.is-error .message__error {
  display: flex;
}

.registration.is-error .message__error .message__image {
  margin: 0 0 20px;
}

.registration.is-error .registration__form {
  display: none;
}

@media (width <= 1258px) {
  .registration .is-validating, .registration .is-complete, .registration .is-error {
    height: 680px;
  }
}

.cursor {
  z-index: 10;
  contain: layout style size;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.cursor.media-blend {
  z-index: 100;
  mix-blend-mode: exclusion;
}

.cursor:before {
  content: "";
  background: #000;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: block;
  position: absolute;
  top: -24px;
  left: -24px;
  transform: scale(.2);
}

.cursor .cursor-media {
  z-index: 99;
  border-radius: 100%;
  width: 150px;
  height: 150px;
  margin: -90px 0 0 -90px;
  display: block;
  position: relative;
  overflow: hidden;
  transform: scale(0);
}

.cursor .cursor-media video {
  height: 180px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
/*# sourceMappingURL=index.edd40aba.css.map */
