html {
  scroll-behavior: smooth;
}

#header {
  height: 100px;
}

#footer {
  height: 60px;
}

.page {
  position: relative;
  min-height: 100vh; /* Ensure the page takes at least the full height of the viewport */

  width: 100%;
  overflow: hidden;
  font-family: Arial, sans-serif;
  font-weight: 300;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    border-bottom: solid 1px $border-primary;
    padding: 0 40px;
    @media (max-width: 767px) {
      height: 75px;
    }
    @media (max-width: 600px) {
      padding: 0 15px;
    }
    &__left {
      display: flex;
      align-items: center;
      color: #777777;
      .dpird__logo {
        display: flex;
        align-items: center ;
        img {
          width: 180px;
          max-width: 180px;
          height: auto;
          margin-right: 20px;
          @media (max-width: 400px) {
            width:90px;
            max-width: 90px;
          }
        }
      }
      .logo {
        margin: 20px;
        img {
          width: 180px;
          max-width: 180px;
          height: auto;
          @media (max-width: 767px) {
            width:120px;
            max-width: 120px;
          }
          @media (max-width: 400px) {
            width:90px;
            max-width: 90px;
          }    
        }
      }
    }
    &__right {
      .statement {
        color: $text;
        img {
          max-width: 150px;
          @media (max-width: 991px) {
            max-width: 125px;
          }
          @media (max-width: 767px) {
            max-width: 100px;
          }
          @media (max-width: 400px) {
            width:80px;
            max-width: 80px;
          }         
        }
      }
    }
  }
  &__introduction {
    padding: 75px 0;
    @media (max-width: 1400px) {
      padding: 50px 0;
      .container {
        max-width: 610px;
        @media (max-width: 767px) {
          width: 100%;
          padding: 0 30px;
        }
      }
    }
  }
  &__footer {
    display: block;
    justify-content: space-between;
    height: 30px;
    border-top: solid 1px $border-primary;
    padding: 15px 40px;
    font-size: 14px;
    color: #828282;
    .row {
      text-align: right;
    }
  }
  &__lead {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 18px;
    color: $heading;
    margin-bottom: 20px;
  }
  &__headline__mobile {
    display: none;
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      .line {
        height: 40px;
        width: 100%;
        display: block;
        overflow: hidden;
        h2 {
          font-size: 34px;
            line-height: 40px;
            transform: translateY(40px);
        }
      }
    }
    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
      .line {
        height: 32px;
        width: 100%;
        display: block;
        overflow: hidden;
        h2 {
          font-size: 24px;
            line-height: 32px;
            transform: translateY(32px);
        }
      }
    }
  }
  &__headline {
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      display: none;
    }
    .line {
      height: 40px;
      width: 100%;
      display: block;
      overflow: hidden;
      @media (max-width: 767px) {
        height: 24px;
      }
      h2 {
        font-size: 34px;
        line-height: 40px;
        color: $heading;
        transform: translateY(50px);
        @media (max-width: 767px) {
          font-size: 17px;
          line-height: 24px;
          transform: translateY(24px);
        }
      }
    }
  }
  &__list {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1400px) {
      width: 560px;
      padding: 0 0 20px 30px;
    }
    @media (max-width: 600px) {
      width: 100%;
      padding: 0 0 20px 30px;
      margin-top: 30px;
    }
    &__item {
      flex: 0 0 50%;
      opacity: 0;
      @media (max-width: 600px) {
        flex: 0 0 100%;
      }
      .list-item {
        text-transform: uppercase;
        font-size: 14px;
        line-height: 20px;
        width: 170px;
        padding-bottom: 30px;
        position: relative;
        @media (max-width: 600px) {
          width: 100%;
          padding-bottom: 20px;
        }
        &.beacon {
          &::after {
            content: "";
            z-index: -1;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            background: none;
            border: 1px solid #003b6933;
            border-radius: 50%;
            transition-property: all;
            transition-duration: .36s;
            position: absolute;
            top: 9px;
            left: -25px;
            transform: translate(-50%, -50%);
          }
          &:hover {
            &:after {
              height: 70px;
              width: 70px;
              opacity: 1;
            }
          }
        }
        &::before {
          content: "";
          position: absolute;
          z-index: 2;
          top: 4px;
          left: -30px;
          width: 10px;
          height: 10px;
          background: transparent;
          border: 2px solid $active;
          border-radius: 50%;
          box-sizing: border-box;
        }
      }
    }
  }

  .cta {
    button {
      color: $heading;
      position: relative;
      background: transparent;
      border: none;
      outline: none;
      padding: 0;
      .icon {
        position: absolute;
        right: -35px;
        top: -1px;
        font-size: 24px;
      }
      &:hover {
          color: $active;
      }
    }
  }
  .hero {
      width: 100%;
      height: 700px;
      overflow: hidden;
      position: relative;
      @media (max-width: 1400px) {
        height: 500px;
      }
      &__image {
        width: 100%;
        height: 700px;
        background: url(./img/extrata-hero.jpg) center center;
        background-size: cover;
        @media (max-width: 1400px) {
          height: 500px;
        }
      }
  }
  .registration {
    position: relative;
      width: 100%;
      padding: 75px 0;
      display: flex;
      justify-content: center;
      @media (max-width: 767px) {
        padding: 50px 0;
      }
      &__form {
          width: 800px;
          position: relative;
          @media (max-width: 1400px) {
            width: 610px;
            max-width: 610px;
          }
          @media (max-width: 767px) {
            width: 100%;
            paddinG: 0 30px;
          }
          form {
            opacity: 0;
          }
      }
      &__process {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        display: none;
        flex-direction: column  ;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 75px  15px;
        p {
          margin: 0;
          @media (max-width: 767px) {
            font-size: 14px;
          }
        }
        h4 {
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
        .message__validating, .message__thankyou, .message__error {
          display: none;
          flex-direction: column;
          position: relative;
          .message__image {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

      }
      &__description {
          font-size:  30px;
          line-height: 40px;
          margin-bottom: 20px;
          @media (max-width: 1400px) {
            max-width: 600px;           
          }
          @media (max-width: 767px) {
            font-size: 22px;
            line-height: 30px;
            width: 100%;
          }
      }
      &__required {
        font-size: 14px;
        color: $text;
        line-height: 18px;
        margin: 0 0 20px;
      }
      .controls {
        @media (max-width: 600px) {
        margin-top: 10px
        }
      }
  }
}

.registered {
  font-size: 14px;
  vertical-align: super;
}

.required {
  color: $required;
}

.link {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  &::before,
  &::after {
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 90%;
    left: 0;
    pointer-events: none;
  }
  &::before {
    content: "";
    /* show by default */
  }
  &::before {
    transform-origin: 100% 50%;
    transform: scale3d(0, 1, 1);
    transition: transform 0.3s;
  }
  &:hover::before {
    transform-origin: 0% 50%;
    transform: scale3d(1, 1, 1);
  }
}


.registration {
  &.is-validating {
    height: 720px;

    .registration__process {
      display: flex;
    }
    .message__image {
      position: relative;
      top: 50px;
    }
    .message__validating {
      display: flex;
    }
    .registration__form {
      display: none;
    }
  }

  &.is-complete {
    height: 720px;

    .registration__process {
      display: flex;
    }
    .message__validating {
      display: none;
    }
    .message__thankyou {
      display: flex;
    }
    .registration__form {
      display: none;
    }
  }

  &.is-error {
    height: 720px;

    .registration__process {
      display: flex;
    }
    .message__validating {
      display: none;
    }
    .message__thankyou {
      display: none;
    }
    .message__error {
      display: flex;
      .message__image {
        margin: 0 0 20px;
      }
    }
    .registration__form {
      display: none;
    }
  }

  @media (max-width: 1258px) {
    .is-validating, .is-complete, .is-error {
      height: 680px;
    }
  }

}