//Forms
form {
  width: 100%;

  &#account .controls {
      margin-top: 0;
  }
  section {
      padding: 30px 0;
      border-bottom: solid 1px $border-primary;

      &:first-child {
          padding: 15px 0 30px 0;
      }
  }

  input.btn {
      width: initial;
  }

  .delete {
      background: $delete;
  }

  .form-title {
      margin-bottom: 10px;
  }

  .form-item,
  label,
  input,
  textarea {
      width: 100%;
      color: $text;
      font-weight: 300;
  }

  .form-group {
      width: 100%;
      margin-bottom: 30px;
      position: relative;
  }

  span.form-required {
      color: $required;
  }

  label {
      margin: 0;
      font-size: 14px;
      text-align: left;
      line-height: 18px;
  }

  input[type="date"],
  input[type="search"],
  input[type="url"],
  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  textarea.form-control {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 10px;
      background-color: $input;
      border: solid 1px $input-border;
      border-radius: 4px;
      color: $text;
      font-weight: 300;

      &:focus,
      &:focus-visible {
          border: solid 1px rgba($app-primary01, 0.4);
          outline: none;
          box-shadow: none;
      }
  }

  textarea.form-control {
      min-height: 120px;
      padding: 10px;
      display: flex;
      justify-content: flex-start;
  }

  input[type="password"] {
      letter-spacing: 5px;
  }

  input {
      height: 54px;
  }

  

  #login-button,
  #reset-password {
      width: 100%;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: $app-primary01;
      border: none;
      margin-bottom: 20px;
      transition: background-color 0.3s ease-in-out;

      &:hover {
          background-color: $hover;
      }
  }

  #btnRegister {
      width: 100%;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: $app-primary01;
      border: none;
      margin-bottom: 20px;
      transition: background-color 0.3s ease-in-out;

      &:hover {
          background-color: $hover;
      }
  }

  .checkbox {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      input[type="checkbox"] {
          width: initial;
          margin-right: 7px;
      }
  }

  .controls {
      display: flex;
      justify-content: space-between;

      .form-group {
          width: initial;
      }
  }

  .forgot-password {
      font-size: 14px;
      font-weight: 500;
      text-align: right;
  }
}

//checkbox
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;

  input {
      position: absolute;
      z-index: -1;
      opacity: 0;
  }

  input:checked ~ .control_indicator {
      background: $active;
      border: solid 1px $active;
  }

  input:checked:focus ~ .control_indicator,
  &:hover input:not([disabled]):checked ~ .control_indicator {
      background: $active;
  }

  input:disabled ~ .control_indicator {
      background: #e6e6e6;
      border: solid 1px #cccccc;
      opacity: 0.6;
      pointer-events: none;
  }

  input:checked ~ .control_indicator:after {
      display: block;
  }
}

.control_indicator {
position: absolute;
top: 1px;
left: 0;
height: 20px;
width: 20px;
background: $white;
border: 1px solid $border-primary;
border-radius: 4px;
}

/* .control:hover input~.control_indicator,
      .control input:focus~.control_indicator {
          background: #cccccc;
      } */

.control_indicator:after {
box-sizing: unset;
content: "";
position: absolute;
display: none;
}

.control-checkbox .control_indicator:after {
left: 7px;
top: 3px;
width: 3px;
height: 8px;
border: solid $white;
border-width: 0 2px 2px 0;
transform: rotate(45deg);
}

.control-checkbox input:disabled ~ .control_indicator:after {
border-color: #7b7b7b;
}

.control-checkbox .control_indicator::before {
content: "";
display: block;
position: absolute;
left: -7px;
top: -5px;
width: 4.5rem;
height: 4.5rem;
margin-left: -1.3rem;
margin-top: -1.3rem;
background: #2aa1c0;
border-radius: 3rem;
opacity: 0.6;
z-index: 99999;
transform: scale(0);
}

@keyframes s-ripple {
0% {
  transform: scale(0);
}
20% {
  transform: scale(1);
}
100% {
  opacity: 0;
  transform: scale(1);
}
}

@keyframes s-ripple-dup {
0% {
  transform: scale(0);
}
30% {
  transform: scale(1);
}
60% {
  transform: scale(1);
}
100% {
  opacity: 0;
  transform: scale(1);
}
}

.control-checkbox input + .control_indicator::before {
animation: s-ripple 250ms ease-out;
}

.control-checkbox input:checked + .control_indicator::before {
animation-name: s-ripple-dup;
}

input:-internal-autofill-selected {
color: $text !important;
}

.form-container {
margin: 50px 0 50px 0;
background: $white;
padding: 30px 30px 20px 30px;
border-radius: 10px;
min-height: 421px;
position: relative;
&.is-complete,
&.is-validating,
&.is-duplicate {
  .form-elements {
    display: none;
  }
  .logo {
    position: absolute;
    width: calc(100% - 60px);
    bottom: 30px;
  }
}
&.is-complete .message__thankyou,
&.is-validating .message__validating,
&.is-duplicate .message__duplicate {
  display: flex;
}

.message__thankyou,
.message__validating,
.message__duplicate {
  display: none;
  flex-direction: column;
  position: absolute;
  width: calc(100% - 60px);
  top: 30px;
  text-align: center;
  padding: 0 30px;
  height: 308px;
  .form__title {
    margin: 10px 0;
    text-align: center;
    h4 {
      text-align: center;
    }
  }
  .message__image {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a {
    display: inline-block;
  }
}
}

form .form-group {
margin-bottom: 15px;
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  height: 44px;
  padding-right: 50px;
}
}

#btnRegister {
height: 44px;
margin-bottom: 5px;
margin: 20px 0 5px 0;
}

.existing-user {
display: flex;
font-size: 14px;
a {
  font-weight: 500;
  margin-left: 5px;
}
}

.logo {
margin-top: 30px;
display: flex;
justify-content: center;
}

.view-password {
position: absolute;
right: 10px;
width: 30px;
height: 30px;
top: 32px;


}

#lottie_thankyou,
#lottie_validating,
#lottie_duplicate {
display: flex;
justify-content: center;
align-items: center;
}

#lottie_thankyou {
max-width: 100px;
height: auto;
}

#lottie_duplicate {
max-width: 80px;
height: auto;
}

.form-label {
margin-bottom: 0;
}

.the-count {
font-size: 14px;
color: $text;
}

//Toggle switch
.switch {
position: relative;
display: inline-block;
width: 25px;
height: 10px;
input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -11px;
    bottom: -5px;
    border: solid 1px $input-border;
    box-shadow: $button-shadow;
    background-color: $white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
}

.slider.round {
border-radius: 34px;
&:before {
  border-radius: 50%;
}
}

input:checked + .slider {
background-color: #2196f3;
}

input:focus + .slider {
box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
-webkit-transform: translateX(26px);
-ms-transform: translateX(26px);
transform: translateX(26px);
}

.bg-light {
background: #eef0f4;
}

.choices__list--dropdown .choices__item--selectable {
padding-right: 1rem;
}

.choices__list--single {
padding: 0;
}

.card {
transform: translateY(-50%);
}

.choices[data-type*="select-one"]:after {
right: 1.5rem;
}

.shadow {
box-shadow: 0.3rem 0.3rem 1rem rgba(178, 200, 244, 0.23);
}

a {
text-decoration: none;
color: inherit;
transition: all 0.3s;
}

/* custom select menu*/

.form-group.dropdown {
.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  .custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
    &.open .custom-options {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    &__trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      color: $text;
      height: 18px;
      line-height: 18px;
      background: $input;
      cursor: pointer;
      border: solid 1px $input-border;
      height: 44px;
      .arrow {
        position: relative;
        height: 15px;
        width: 15px;
        transform: scale(0.4);
      }

      .arrow::before,
      .arrow::after {
        content: "";
        position: absolute;
        bottom: 0px;
        width: 0.15rem;
        height: 100%;
        transition: all 0.5s;
      }

      .arrow::before {
        left: -5px;
        transform: rotate(45deg);
        background-color: #394a6d;
      }

      .arrow::after {
        left: 5px;
        transform: rotate(-45deg);
        background-color: #394a6d;
      }

      .open .arrow::before {
        left: -5px;
        transform: rotate(-45deg);
      }

      .open .arrow::after {
        left: 5px;
        transform: rotate(45deg);
      }

      span {
        margin-right: 10px;
        color: $text;
        font-weight: 500;
        &::before {
          content: "";
          margin-right: 5px;
          color: #777777;
          font-weight: 500;
        }
      }
    }
    .custom-options {
      position: absolute;
      display: block;
      top: 43px;
      left: 0;
      width: 100%;
      padding: 15px;
      border: 1px solid $input-border;
      background: #fff;
      box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.15);
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      z-index: 2;
      .custom-option {
        position: relative;
        display: block;
        padding: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #777777;
        line-height: 26px;
        cursor: pointer;
        transition: all 0.5s;
        &:hover {
          cursor: pointer;
          background-color: #ffffff;
        }
        &.selected {
          color: rgba(119, 119, 119, 0.6);
        }
      }
    }
  }
}
}

.date-range {
width: 200px;
max-width: 200px;
}

#ToDate,
#FromDate {
border-radius: 4px;
}

.range {
position: relative;
width: 50px;
height: 22px;
top: 24px;
.line {
  width: 100%;
  height: 1px;
  background: $border-primary;
  position: absolute;
  top: 22px;
  &::before,
  &::after {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: $emphasize;
    position: absolute;
    top: -1px;
    z-index: 1;
  }
  &::before {
    left: -1px;
  }
  &::after {
    right: -2px;
  }
}
}

.form-group.calendar {
display: flex;
}

.input-daterange {
display: flex;
flex-direction: column;
}






.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 99;
}

.controls {
  margin-top: 50px;
  &.single {
      justify-content: flex-end;
      display: flex;
  }
  &.full {
    display: flex;
    justify-content: center;
    .button {
      width: 100%;
      button {
        width: 100%;
      }
    }
    
}
}

